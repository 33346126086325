@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
/*  */
@import "./variaveis.css";
@import "./body.css";
@import "./moduleInicial.css";
@import "./content-related.css"; 
@import "./base.css";
@import "./login.css";
@import "./header.css";
@import "./navbar.css";
@import "./table.css";
@import "./inputs.css";
