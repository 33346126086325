@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap";
body {
  --primary: #05f683;
  --secondary: #11d276;
  --default-button-hover-bg: #59e0a0;
  --save-and-continue: #e8fbf2;
  --header-link-color: #333;
  --body-bg: #fdfdfd;
  --secondary-button-bg: #0000000f;
  --secondary-button-hover-bg: #00000026;
  --body-quiet-color: #575757;
  --accent: white;
  --primary-fg: #fff;
  --body-fg: #333;
  --body-loud-color: #000;
  --header-color: white;
  --header-branding-color: var(--accent);
  --header-bg: var(--secondary);
  --breadcrumbs-fg: var(--accent);
  --breadcrumbs-link-fg: var(--body-bg);
  --breadcrumbs-bg: var(--primary);
  --link-fg: #131313;
  --link-hover-color: var(--secondary);
  --link-selected-fg: var(--secondary);
  --hairline-color: #e8e8e8;
  --border-color: #ccc;
  --error-fg: #ba2121;
  --message-success-bg: #dfd;
  --message-warning-bg: #ffc;
  --message-error-bg: #ffefef;
  --darkened-bg: #f8f8f8;
  --selected-bg: #e4e4e4;
  --selected-row: #ffc;
  --button-fg: #fff;
  --button-bg: var(--primary);
  --button-hover-bg: var(--secondary);
  --default-button-bg: var(--secondary);
  --close-button-bg: #888;
  --close-button-hover-bg: #747474;
  --delete-button-bg: #ba2121;
  --delete-button-hover-bg: #a41515;
  --object-tools-fg: var(--button-fg);
  --object-tools-bg: var(--close-button-bg);
  --object-tools-hover-bg: var(--close-button-hover-bg);
  --font-family: "Montserrat", sans-serif;
  opacity: 0;
  will-change: opacity;
  transition: opacity .2s linear, transform .3s;
  font-family: var(--font-family) !important;
}

body * {
  font-family: var(--font-family) !important;
}

.dashboard.page-inicial #content {
  flex-direction: row;
}

.dashboard.page-inicial .module {
  background: var(--card-color, white);
  border: 0 solid #0000;
  border-radius: .25rem;
  overflow: hidden;
  box-shadow: 0 .0625rem .1875rem #0000000d, 0 .0625rem .125rem #0003, -.0625rem -.0625rem 1.25rem #ced4da4d;
}

.dashboard.page-inicial .module tbody tr {
  background: var(--card-color, white);
  width: 100%;
  display: flex;
}

.dashboard.page-inicial .module tbody tr td {
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.dashboard.page-inicial .module tbody tr td:last-child {
  padding-left: .5rem !important;
}

.dashboard.page-inicial .module tbody tr td:last-child a {
  margin-right: .5rem;
}

.dashboard.page-inicial .module tbody tr th {
  flex: 100%;
  padding: 0;
  display: flex;
}

.dashboard.page-inicial .module tbody tr th a {
  width: 100%;
  color: var(--header-link-color);
  padding: .9375rem .9375rem .9375rem 1.5rem;
  font-size: .875rem;
  font-weight: 600;
  display: flex;
}

.dashboard.page-inicial .module tbody tr th a:hover {
  color: var(--primary);
  text-decoration: underline;
}

.dashboard.page-inicial .module tbody tr:hover {
  background: var(--darkened-bg);
}

.dashboard.page-inicial .module table {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.dashboard.page-inicial .module tr:nth-child(2n), .dashboard.page-inicial .module tr:nth-child(2n) .errorlist, .dashboard.page-inicial .module tr:nth-child(odd) + .row-form-errors, .dashboard.page-inicial .module tr:nth-child(odd) + .row-form-errors .errorlist {
  background: var(--body-bg);
}

.dashboard.page-inicial #content {
  flex-direction: row;
  margin-top: 32px;
}

.dashboard.page-inicial #content > h1 {
  flex: 100%;
}

.dashboard.page-inicial #content #content-related .module {
  box-sizing: border-box;
}

.dashboard.page-inicial #content #content-related .module > h2 {
  color: #00000094;
  padding: 24px 16px 16px;
  font-size: 13px;
  font-weight: 700;
}

.dashboard.page-inicial #content #content-related .module > h3 {
  color: #00000094;
  margin: 0;
  padding: 0 16px;
  font-size: 13px;
  font-weight: 700;
}

.dashboard.page-inicial #content #content-related .module .actionlist {
  margin: 0;
  padding: 8px;
}

.dashboard.page-inicial #content #content-related .module .actionlist li {
  border-top: 1px solid var(--hairline-color);
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  margin: 0;
  padding: 8px;
  line-height: 100%;
  display: flex;
}

.dashboard.page-inicial #content #content-related .module .actionlist li br {
  display: none;
}

.dashboard.page-inicial #content #content-related .module .actionlist li a {
  font-size: 13px;
}

.dashboard #content-related {
  background: var(--card-color, white);
}

.dashboard .module > h2 {
  font-size: 18px;
  font-weight: 700;
}

.dashboard .module .actionlist li {
  padding: 0;
  background: initial !important;
}

.dashboard .module .actionlist li a {
  color: var(--body-quiet-color);
}

.dashboard .module .actionlist li a:hover {
  text-decoration: underline;
}

.dashboard .content {
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 90rem !important;
}

.dashboard #content {
  box-sizing: border-box;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  padding: 0 .9375rem;
  display: flex;
}

.dashboard #content > h1 {
  flex: 100%;
  margin-top: 2rem;
  margin-bottom: 0;
  font-weight: 700;
}

.dashboard #content-main {
  float: none;
  flex: 1;
}

.dashboard #content-related {
  float: none;
  margin-right: 0;
}

.dashboard #content {
  flex-direction: column;
  display: flex;
}

.dashboard #changelist-search > div {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.dashboard #changelist-search > div [type="submit"] {
  height: 100%;
  min-height: 2.1875rem;
  font-weight: 500;
  display: inline-flex;
  box-shadow: initial !important;
}

.dashboard #changelist .actions {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.dashboard #changelist .actions * {
  float: none;
}

.dashboard #changelist .actions label {
  align-items: center;
  display: flex;
}

.dashboard #changelist .actions [type="submit"] {
  justify-content: center;
  align-items: center;
  min-width: 3.125rem;
  height: 100%;
  min-height: 2.1875rem;
  font-weight: 500;
  display: inline-flex;
  box-shadow: initial !important;
}

.dashboard .module {
  background: var(--card-color, white);
  border: 0 solid #0000;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px #0000000d, 0 1px 2px #0003, -1px -1px 20px #ced4da4d;
}

.dashboard .module .module {
  border-radius: initial;
  box-shadow: initial;
  border-radius: initial;
  background: initial;
}

.dashboard .module caption {
  background: initial;
  width: 100%;
  padding-top: 24px;
  padding-left: 12px;
}

.dashboard .module caption > a {
  color: #00000094;
  width: 100%;
  padding: 6px 0 6px 12px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  position: relative;
}

.dashboard .module caption > a:after {
  content: "";
  background: var(--primary);
  border-radius: 999px;
  width: 2px;
  height: 100%;
  margin: auto 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -12px;
}

.dashboard .module .form-row:hover {
  background-color: #f2f2f2;
}

.dashboard .module .addlink {
  background: initial;
  background-color: var(--default-button-bg);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMVY5IiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTkgNUgxIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  background-position: 8px;
  background-repeat: no-repeat;
  background-size: 12px;
  border-radius: 4px;
  align-items: center;
  padding: 8px 16px 8px 24px;
  transition: background-color .3s;
  display: inline-flex;
  color: #fff !important;
}

.dashboard .module .addlink:hover {
  background-color: var(--default-button-hover-bg);
  text-decoration: none !important;
}

.dashboard .module .viewlink {
  background: initial;
  background-color: var(--secondary-button-bg);
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBmaWxsPSJub25lIj48cGF0aCBkPSJNMCAwaDI0djI0aC0yNFoiPjwvcGF0aD48cGF0aCBzdHJva2U9IiMzMzMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0zLjExOCAxMi40NjdjLS4xNTctLjI5MS0uMTU3LS42NDQgMC0uOTM1IDEuODkyLTMuNDk5IDUuMzg3LTYuNTMyIDguODgyLTYuNTMyIDMuNDk1IDAgNi45OSAzLjAzMyA4Ljg4MiA2LjUzMyAuMTU3LjI5MS4xNTcuNjQ0IDAgLjkzNSAtMS44OTIgMy40OTktNS4zODcgNi41MzItOC44ODIgNi41MzIgLTMuNDk1IDAtNi45OS0zLjAzMy04Ljg4Mi02LjUzM1oiPjwvcGF0aD48cGF0aCBzdHJva2U9IiMzMzMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjQyODYiIGQ9Ik0xNC4xMjEzIDkuODc4NjhjMS4xNzE1NyAxLjE3MTU3IDEuMTcxNTcgMy4wNzEwNyAwIDQuMjQyNjQgLTEuMTcxNTcgMS4xNzE1Ny0zLjA3MTA3IDEuMTcxNTctNC4yNDI2NCAwIC0xLjE3MTU3LTEuMTcxNTctMS4xNzE1Ny0zLjA3MTA3IDAtNC4yNDI2NCAxLjE3MTU3LTEuMTcxNTcgMy4wNzEwNy0xLjE3MTU3IDQuMjQyNjQgMCI+PC9wYXRoPjwvZz48L3N2Zz4=");
  background-position: 8px;
  background-repeat: no-repeat;
  background-size: 12px;
  border-radius: 4px;
  align-items: center;
  padding: 8px 16px 8px 24px;
  transition: background-color .3s;
  display: inline-flex;
  color: var(--header-link-color) !important;
}

.dashboard .module .viewlink:hover {
  background-color: var(--secondary-button-hover-bg);
  text-decoration: none !important;
}

.dashboard .module .changelink {
  background: initial;
  background-color: var(--secondary-button-bg);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjUzOTkgMTAuMTJMMTMuODc5OSA2LjQ1OTk2IiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYuMjUxIDIwLjk5OThIM1YxNy43NDg4QzMgMTcuNDgzOCAzLjEwNSAxNy4yMjg4IDMuMjkzIDE3LjA0MThMMTYuNjI3IDMuNzA2ODJDMTcuMDE4IDMuMzE1ODIgMTcuNjUxIDMuMzE1ODIgMTguMDQxIDMuNzA2ODJMMjAuMjkyIDUuOTU3ODJDMjAuNjgzIDYuMzQ4ODIgMjAuNjgzIDYuOTgxODIgMjAuMjkyIDcuMzcxODJMNi45NTggMjAuNzA2OEM2Ljc3MSAyMC44OTQ4IDYuNTE2IDIwLjk5OTggNi4yNTEgMjAuOTk5OFYyMC45OTk4WiIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  background-position: 8px;
  background-repeat: no-repeat;
  background-size: 12px;
  border-radius: 4px;
  align-items: center;
  padding: 8px 16px 8px 24px;
  font-weight: 600;
  transition: background-color .3s;
  display: inline-flex;
  color: var(--header-link-color) !important;
}

.dashboard .module .changelink:hover {
  background-color: var(--secondary-button-hover-bg);
  text-decoration: none !important;
}

.dashboard .change-related {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjUzOTkgMTAuMTJMMTMuODc5OSA2LjQ1OTk2IiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYuMjUxIDIwLjk5OThIM1YxNy43NDg4QzMgMTcuNDgzOCAzLjEwNSAxNy4yMjg4IDMuMjkzIDE3LjA0MThMMTYuNjI3IDMuNzA2ODJDMTcuMDE4IDMuMzE1ODIgMTcuNjUxIDMuMzE1ODIgMTguMDQxIDMuNzA2ODJMMjAuMjkyIDUuOTU3ODJDMjAuNjgzIDYuMzQ4ODIgMjAuNjgzIDYuOTgxODIgMjAuMjkyIDcuMzcxODJMNi45NTggMjAuNzA2OEM2Ljc3MSAyMC44OTQ4IDYuNTE2IDIwLjk5OTggNi4yNTEgMjAuOTk5OFYyMC45OTk4WiIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1.125rem;
  border-radius: .25rem;
  place-items: center;
  width: 1.5625rem;
  height: 1.5625rem;
  margin: 0;
  transition: background-color .2s;
  display: grid;
}

.dashboard .change-related:hover {
  background-color: #00000026;
}

.dashboard .change-related img {
  display: none;
}

.dashboard .add-related {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMVY5IiBzdHJva2U9IiMxMUQyNzYiIHN0cm9rZS13aWR0aD0iMC41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTkgNUgxIiBzdHJva2U9IiMxMUQyNzYiIHN0cm9rZS13aWR0aD0iMC41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1.125rem;
  border-radius: .25rem;
  place-items: center;
  width: 1.5625rem;
  height: 1.5625rem;
  margin: 0;
  transition: background-color .2s;
  display: grid;
}

.dashboard .add-related:hover {
  background-color: #00000026;
}

.dashboard .add-related img {
  display: none;
}

.dashboard .related-widget-wrapper {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.dashboard .vCheckboxLabel {
  cursor: pointer;
  user-select: none;
}

.dashboard .module > h2, .dashboard .module > caption, .dashboard .inline-group > h2 {
  color: #fff;
}

.dashboard .inline-related > h3 {
  color: var(--primary);
  border: initial;
  background: #f4f4f4;
  padding: 1.5rem .5rem 1.5rem .75rem;
  font-size: 1.125rem;
  position: relative;
}

.dashboard .inline-related > h3:after {
  content: "";
  background: var(--primary);
  border-radius: 50%;
  width: .125rem;
  height: 40%;
  margin: auto 0;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.dashboard #changelist-filter li.selected {
  color: var(--link-fg) !important;
}

.dashboard #changelist-filter li.selected a {
  font-weight: 700;
  color: var(--link-fg) !important;
}

.dashboard .aligned label {
  font-size: .875rem;
}

.dashboard .filtered {
  min-height: initial !important;
}

.dashboard .add-row {
  padding: 0 !important;
}

.dashboard .add-row a {
  background-repeat: no-repeat;
  margin-bottom: -.0625rem;
  font-weight: 600;
  display: flex;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMVY5IiBzdHJva2U9IiMxMUQyNzYiIHN0cm9rZS13aWR0aD0iMC41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTkgNUgxIiBzdHJva2U9IiMxMUQyNzYiIHN0cm9rZS13aWR0aD0iMC41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") .3125rem / 1.5rem no-repeat !important;
  height: max-content !important;
  padding: 1rem 1rem 1rem 2.125rem !important;
  font-size: .9375rem !important;
}

.dashboard .add-row a:hover {
  background-color: #e5e5e5 !important;
}

.dashboard .object-tools a {
  color: #333;
  text-align: center;
  background-color: #ededed8f;
  border: .0625rem solid #33333317;
  padding: 6px 12px;
  font-weight: 600;
  transition: border .3s;
  border-radius: .25rem !important;
}

.dashboard .object-tools a.addlink {
  background-position: calc(100% - 8px);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMVY5IiBzdHJva2U9IiMxMUQyNzYiIHN0cm9rZS13aWR0aD0iMC41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTkgNUgxIiBzdHJva2U9IiMxMUQyNzYiIHN0cm9rZS13aWR0aD0iMC41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") !important;
  background-repeat: no-repeat !important;
  background-size: .875rem !important;
  padding-right: 28px !important;
}

.dashboard .object-tools a:hover {
  background-color: #e6e6e69e;
  border: .0625rem solid #3333334b;
}

.dashboard .paginator {
  align-items: center;
  gap: 4px;
  display: flex;
}

.dashboard .paginator a {
  text-align: center;
  border-radius: 50%;
  place-items: center;
  height: 30px;
  padding: 0;
  font-weight: 600;
  display: grid;
  width: 30px !important;
}

.dashboard .paginator span {
  color: var(--primary);
  font-weight: 600;
}

.dashboard .paginator a.showall {
  border: 1px solid #0000;
  border-radius: 4px;
  margin-left: 16px;
  padding: 8px 16px;
  transition: all .3s;
  width: max-content !important;
}

.dashboard .paginator a.showall:hover {
  border-color: #0003;
}

.dashboard .tabular .original p {
  height: initial !important;
  position: initial !important;
  min-width: 120px !important;
  max-width: 200px !important;
  display: flex !important;
}

.dashboard .module.collapsed h2 {
  background-color: var(--primary) !important;
}

.dashboard .module.scroll {
  overflow-y: auto;
}

.dashboard.login #content {
  width: auto;
}

.dashboard.login #header h1 a {
  color: var(--accent);
}

.dashboard.login .button:active, .dashboard.login input[type="submit"]:active, .dashboard.login input[type="button"]:active, .dashboard.login .button:focus, .dashboard.login input[type="submit"]:focus, .dashboard.login input[type="button"]:focus, .dashboard.login .button:hover, .dashboard.login input[type="submit"]:hover, .dashboard.login input[type="button"]:hover {
  background: var(--default-button-hover-bg);
}

.dashboard #header a {
  color: var(--accent);
}

.dashboard .toggle-nav-sidebar, .dashboard #nav-sidebar {
  display: none !important;
}

.dashboard .main.shifted > #nav-sidebar + .content {
  width: 100% !important;
}

.dashboard table tbody tr input[type="checkbox"] {
  cursor: pointer;
}

.dashboard table tbody tr * {
  vertical-align: middle;
  line-height: 110%;
}

.dashboard table tbody tr th {
  vertical-align: middle !important;
}

.dashboard table tbody tr:nth-child(2n), .dashboard table tbody tr:nth-child(2n) .errorlist, .dashboard table tbody tr:nth-child(odd) + .row-form-errors, .dashboard table tbody tr:nth-child(odd) + .row-form-errors .errorlist {
  background: #fff;
}

.dashboard table tbody tr:hover {
  background: #f2f2f2;
}

.dashboard input[type="text"], .dashboard input[type="password"], .dashboard input[type="email"], .dashboard input[type="url"], .dashboard input[type="number"], .dashboard input[type="tel"], .dashboard textarea, .dashboard select, .dashboard .vTextField {
  box-sizing: border-box;
  min-height: 35px;
}

.dashboard input[type="text"]:focus-visible, .dashboard input[type="password"]:focus-visible, .dashboard input[type="email"]:focus-visible, .dashboard input[type="url"]:focus-visible, .dashboard input[type="number"]:focus-visible, .dashboard input[type="tel"]:focus-visible, .dashboard textarea:focus-visible, .dashboard select:focus-visible, .dashboard .vTextField:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.dashboard input[type="text"]:focus, .dashboard input[type="password"]:focus, .dashboard input[type="email"]:focus, .dashboard input[type="url"]:focus, .dashboard input[type="number"]:focus, .dashboard input[type="tel"]:focus, .dashboard textarea:focus, .dashboard select:focus, .dashboard .vTextField:focus {
  border-color: var(--primary);
}

.dashboard input[name="_continue"] {
  background: var(--save-and-continue);
  color: var(--link-fg);
  border: 1px solid #0000;
}

.dashboard input[name="_continue"]:hover {
  border-color: var(--border-color);
}

.dashboard input[name="_addanother"] {
  color: var(--link-fg);
  background: none;
  border: 1px solid #0000;
}

.dashboard input[name="_addanother"]:hover {
  border-color: var(--border-color);
}

.dashboard .submit-row input[type="submit"] {
  font-weight: 600;
}

.dashboard .vCheckboxLabel {
  font-weight: 600 !important;
}
/*# sourceMappingURL=@admin.css.map */
